import React from 'react';

const Element = React.lazy(() => import('./add_d.component'));

export default {
    index: true,
    path:"Add_d",
    title: "add_d",
    element: <Element />
};
