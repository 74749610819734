import Config from "./config";
import * as apiTypes from "./types/api/";



class User {
    create = API.call.account.postApi<apiTypes.AccountDetail, apiTypes.Success>("user/create");
    get = API.call.account.getParamsApi<number, apiTypes.AccountDetail>("user/");
    createToken = API.call.account.postApi<apiTypes.CreateToken, apiTypes.ResponseToken>("user/token/create");
    list = API.call.account.getApi<apiTypes.AccountSimple[]>("user/list");

    requestList = API.call.account.getApi<apiTypes.RequestItem[]>("user/request/list");
    requestDetail = API.call.account.getParamsApi<number, apiTypes.RequestItem>("user/request/?");
    requestAccept = API.call.account.postParamsApi<number, undefined, apiTypes.Success>("user/request/?/accept");
    requestReject = API.call.account.postParamsApi<number, undefined, apiTypes.Success>("user/request/?/reject");

    teamDetail = API.call.account.getParamsApi<number, apiTypes.TeamDetail>("team/?");
    teamCreate = API.call.account.postApi<apiTypes.TeamCreateParam, apiTypes.TeamMeta>("team/create");
    teamJoined = API.call.account.getApi<apiTypes.TeamMeta[]>("team/joined");
    teamMembers = API.call.account.getParamsApi<number, apiTypes.TeamMember[]>("team/?/members");
    teamMemberAdd = API.call.account.postParamsApi<number, { mail: string, roleId: number }, apiTypes.Success>("team/?/add");

    roleCreate = API.call.account.postApi<apiTypes.RoleCreateParam, apiTypes.Role>("role/create");
    roleList = API.call.account.getApi<apiTypes.Role[]>("role/list");
    rolePermissions = API.call.account.getParamsApi<number, apiTypes.RolePermission[]>("role/?");
    rolePermissionsFinally = API.call.account.getParamsApi<number, any>("role/?/finally");
}

class Session {
    challange = API.call.account.postApi<apiTypes.ChallangeRequest, apiTypes.ChallangeKey>("session/challange");
    login = API.call.account.postApi<apiTypes.LoginRequest, {}>("session/login");
    check = API.call.account.getApi<apiTypes.AccountDetail>("session/check");
    logout = API.call.account.getApi<apiTypes.Success>("session/logout");
    debug = API.call.account.getApi<any>("session/debug");
}


class Contnets {
    getItem = API.call.contents.getParamsApi<number, apiTypes.ItemSimple>("item/");
    editItem = API.call.contents.postParamsApi<number, apiTypes.ItemEditParams, apiTypes.Success>("item/");
    deleteItem = API.call.contents.deleteParamsApi<number, apiTypes.ItemSimple>("item/");
    restoreItem = API.call.contents.postParamsApi<number, undefined, apiTypes.ItemSimple>("item/restore/");
    searchItem = API.call.contents.getQueryApi<apiTypes.ItemSearchParams, apiTypes.ItemSimple[]>("item/search");

    listItem = API.call.contents.getParamsApi<number, apiTypes.ItemSimpleList>("item/list/");
    listItemByPath = API.call.contents.getQueryApi<apiTypes.ListItemQuery, apiTypes.ItemSimpleList>("item/list");
    itemAdd = API.call.contents.postApi<apiTypes.ItemAddParams, apiTypes.ItemSimple>("item/add");

    getConfig = API.call.contents.getApi<apiTypes.AppConfig>("app/config");

    dataGet = API.call.contents.getParamsApi<number, apiTypes.DataSimple<any>[]>("/data/?");
    dataAdd = API.call.contents.postParamsApi<number, apiTypes.DataAdd<any>, apiTypes.Success>("/data/?");
    dataAppAdd = API.call.contents.postParamsApi<string, apiTypes.DataAppAdd, apiTypes.Success>("/data/app/?/insert");

    sfuRooms = API.call.contents.getApi<apiTypes.SfuRoom[]>("sfu/rooms");
    sfuCreateRoom = API.call.contents.postApi<void, apiTypes.SfuRoom>("sfu/rooms/create");
    sfuRoom = API.call.contents.getParamsApi<string, apiTypes.SfuRoom>("sfu/rooms/?")
    sfuGetCapabilities = API.call.contents.getParamsApi<string, any>("sfu/rooms/?/capabilities")
    sfuTransportNew = API.call.contents.postParamsApi<string, apiTypes.NewTransportOptions, apiTypes.ProducerTransport>("sfu/rooms/?/transport/new");
    sfuTransportConnect = API.call.contents.postParamsApi<string, apiTypes.ProducerConnect, apiTypes.Success>("sfu/rooms/?/transport/connect");

    sfuProduceStart = API.call.contents.postParamsApi<string, apiTypes.ProducerStart, apiTypes.Success>("sfu/rooms/?/produce/start");
    sfuConsumeStart = API.call.contents.postParamsApi<string, apiTypes.ConsumeStart, apiTypes.ConsumeInfo>("sfu/rooms/?/consume/start");
}

class Call {

    endPoint: string;
    constructor(endPoint: string) {
        this.endPoint = endPoint;
    }

    async get<RES>(path: string): Promise<RES> {
        const res = await fetch(this.endPoint + path, {
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });


        try {
            const json = await res.json();

            if (res.status != 200) {
                throw new Error(`${res.status}: ${json.message}`);
            }

            return json;

        } catch (ex) {
            throw new Error(`${res.status}: API Error`);
        }
    }

    async post<REQ, RES>(path: string, data: REQ): Promise<RES> {
        const res = await fetch(this.endPoint + path, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // 本体のデータ型は "Content-Type" ヘッダーと一致させる必要があります
        });

        var json: any = {};

        try {
            json = await res.json();
        } catch (ex) {
            json = { message: "Unknown API Error" }
        }


        if (res.status != 200) {
            throw new Error(`${res.status}: ${json.message}`);
        }
        return json;
    }
    async delete<RES>(path: string): Promise<RES> {
        const res = await fetch(this.endPoint + path, {
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        });


        try {
            const json = await res.json();

            if (res.status != 200) {
                throw new Error(`${res.status}: ${json.message}`);
            }

            return json;

        } catch (ex) {
            throw new Error(`${res.status}: API Error`);
        }
    }


    postApi<REQ, RES>(path: string): (data: REQ) => Promise<RES> {
        return (data: REQ) => this.post(path, data);
    }
    getApi<RES>(path: string): () => Promise<RES> {
        return () => this.get(path);
    }
    getParamsApi<PARAM, RES>(path: string): (param: PARAM) => Promise<RES> {
        if (path.indexOf("?") != -1)
            return (param: any) => this.get(path.replace("?", encodeURI(param.toString())));
        else
            return (param: any) => this.get(path + encodeURI(param.toString()));
    }
    getQueryApi<PARAM, RES>(path: string): (param: PARAM) => Promise<RES> {
        return (param: any) => {
            let query = "";
            for (const key of Object.keys(param)) {
                if (param[key] == undefined) continue;

                if (query != "") query += `&`;
                query += `${key}=${encodeURI(param[key])}`;
            }
            return this.get(path + "?" + query);
        };
    }
    postParamsApi<PARAM, REQ, RES>(path: string): (param: PARAM, data: REQ) => Promise<RES> {

        if (path.indexOf("?") != -1)
            return (param: any, data: REQ) => this.post<REQ, RES>(path.replace("?", encodeURI(param.toString())), data);
        else
            return (param: any, data: REQ) => this.post<REQ, RES>(path + encodeURI(param.toString()), data);

    }
    deleteParamsApi<PARAM, RES>(path: string): (param: PARAM) => Promise<RES> {
        return (param: any) => this.delete(path + encodeURI(param.toString()));
    }
}

export default class API {
    static call = {
        account: new Call(Config.endpoint.account),
        contents: new Call(Config.endpoint.contents),

    }

    static session = new Session();
    static user = new User();
    static contents = new Contnets();
}
