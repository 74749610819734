import React from 'react';

const Element = React.lazy(() => import('./view.component'));

export default {
    index: true,
    path:"view",
    title: "View",
    element: <Element />
};
