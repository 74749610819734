import Add_d from './add_d';
import Add_w from './add_w';
import View from './view';
import Test from './test';
import List from './list';
import { Outlet } from 'react-router-dom';

export default {
    path: 'data',
    element: <Outlet />,
    children: [
        Add_d,
        Add_w,
        View,
        Test,
        List
    ]
}