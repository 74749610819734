// import React from 'react';
import { AppBar, Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, 
        ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography,
         } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CreateIcon from '@mui/icons-material/Create';
import GroupsIcon from '@mui/icons-material/Groups';
import React from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { RootLoaderData } from "./LoaderData";
// import { redirect, useNavigate } from "react-router-dom";

const drawerWidth = 240;


export default function AppHeader() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const { session } = useLoaderData() as RootLoaderData;

  const settings = [
    session == null ? { text: "Login", href: "https://milc-account.sharo-dev.com/account/login" } : undefined,
    session == null ? { text: "Register", href: "https://milc-account.sharo-dev.com/account/register" } : undefined,
    session != null ? { text: "Account", href: "https://milc-account.sharo-dev.com/account/check" } : undefined,
    session != null ? { text: "Log out", href: "https://milc-account.sharo-dev.com/account/logout" } : undefined,
  ]

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/data/view">
              <ListItemIcon>
                <SignalCellularAltIcon />
              </ListItemIcon>
              <ListItemText primary="View" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/data/add_d">
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Input - Day" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/data/add_w">
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Input - Week" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/debug/team">
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary="Team" />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={handleDrawerToggle}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="a" sx={{ flexGrow: 1 }}
            onClick={() => { navigate("/"); }}
            style={{ color: 'white', textDecoration: 'none' }}>
            CARRY
          </Typography>

          {/* <Button color="inherit" href="/login">Login</Button> */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={(event) => { setAnchorEl(event.currentTarget); }} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => { setAnchorEl(null); }}
            >
              {settings.filter(i => Boolean(i)).map(item => (
                <MenuItem key={item!.text} onClick={() => { location.href = (item!.href); setAnchorEl(null); }}>
                  <Typography textAlign="center">{item!.text}</Typography>
                </MenuItem>))}

            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block'},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      ></Box>
    </Box>
  );
}