import React from 'react';

const Element = React.lazy(() => import('./list.component'));

export default {
    index: true,
    path:"List",
    title: "list",
    element: <Element />
};
