import React from 'react';

const Element = React.lazy(() => import('./test.component'));

export default {
    index: true,
    path:"Test",
    title: "test",
    element: <Element />
};
