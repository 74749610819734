import React from 'react';

const Element = React.lazy(() => import('./add_w.component'));

export default {
    index: true,
    path:"Add_w",
    title: "add_w",
    element: <Element />
};
