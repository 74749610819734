import Insert from './Insert';
import Get from './get';
import Team from './team';
import { Outlet } from 'react-router-dom';

export default {
    path: 'debug',
    element: <Outlet />,
    children: [
        Insert,
        Get,
        Team,
    ]
}